const Runtime = require('../engine/runtime');
const BlockType = require('../extension-support/block-type');
const ScratchBlocksConstants = require('../engine/scratch-blocks-constants');
const maybeFormatMessage = require('../util/maybe-format-message');
const ArgumentType = require('../extension-support/argument-type');
const xmlEscape = require('../util/xml-escape');
const {getExtensionInstanceId, getRawOpcode, replaceDeviceExtensionOpcode} = require('./extension-id');
const uid = require('../util/uid');
const execute = require('../engine/execute');
const Thread = require('../engine/thread');
const TargetType = require('../extension-support/target-type');
const dispatch = require('../dispatch/central-dispatch');
const ml5 = require('./tensorflow/ml5.min.js');

const defaultBlockPackages = {
    scratch3_control: require('../blocks/scratch3_control'),
    scratch3_event: require('../blocks/scratch3_event'),
    scratch3_looks: require('../blocks/scratch3_looks'),
    scratch3_motion: require('../blocks/scratch3_motion'),
    scratch3_operators: require('../blocks/scratch3_operators'),
    scratch3_sound: require('../blocks/scratch3_sound'),
    scratch3_sensing: require('../blocks/scratch3_sensing'),
    scratch3_data: require('../blocks/scratch3_data'),
    scratch3_procedures: require('../blocks/scratch3_procedures')
};

const defaultExtensionColors = ['#0FBD8C', '#0DA57A', '#0B8E69'];

const ArgumentTypeMap = (() => {
    const map = {};
    map[ArgumentType.ANGLE] = {
        shadow: {
            type: 'math_angle',
            // We specify fieldNames here so that we can pick
            // create and populate a field with the defaultValue
            // specified in the extension.
            // When the `fieldName` property is not specified,
            // the <field></field> will be left out of the XML and
            // the scratch-blocks defaults for that field will be
            // used instead (e.g. default of 0 for number fields)
            fieldName: 'NUM'
        }
    };
    map[ArgumentType.COLOR] = {
        shadow: {
            type: 'colour_picker',
            fieldName: 'COLOUR'
        }
    };
    map[ArgumentType.NUMBER] = {
        shadow: {
            type: 'math_number',
            fieldName: 'NUM'
        }
    };
    map[ArgumentType.STRING] = {
        shadow: {
            type: 'text',
            fieldName: 'TEXT'
        }
    };
    map[ArgumentType.BOOLEAN] = {
        check: 'Boolean'
    };
    map[ArgumentType.MATRIX] = {
        shadow: {
            type: 'matrix',
            fieldName: 'MATRIX'
        }
    };
    map[ArgumentType.NOTE] = {
        shadow: {
            type: 'note',
            fieldName: 'NOTE'
        }
    };
    map[ArgumentType.IMAGE] = {
        // Inline images are weird because they're not actually "arguments".
        // They are more analagous to the label on a block.
        fieldType: 'field_image'
    };

    map[ArgumentType.SETTINGS] = {
        shadow: {
            type: 'settings',
            fieldName: 'SETTINGS'
        }
    };

    map[ArgumentType.IMGPREVIEW] = {
        shadow: {
            type: 'img_preview',
            fieldName: 'IMGPREVIEW'
        }
    };

    map[ArgumentType.FORMSETTING] = {
        shadow: {
            type: 'form_settings',
            fieldName: 'FORMSETTING'
        }
    };

    map[ArgumentType.RANGE] = {
        shadow: {
            type: 'math_range',
            fieldName: 'NUM'
        }
    };

    map[ArgumentType.SLIDER] = {
        shadow: {
            type: 'math_slider',
            fieldName: 'NUM'
        }
    };
    map[ArgumentType.TEXTPREVIEW] = {
        shadow: {
            type: 'text_preview'
        }
    };

    map[ArgumentType.DIRSETTINGS] = {
        shadow: {
            type: 'dir_setting'
        }
    };
    map[ArgumentType.IMGSETTINGS] = {
        shadow: {
            type: 'img_setting'
        }
    };
    map[ArgumentType.ONLINEIMGSETTING] = {
        shadow: {
            type: 'online_img_setting'
        }
    };
    map[ArgumentType.COLORPALETTE] = {
        shadow: {
            type: 'colour_palette'
        }
    };
    map[ArgumentType.PIANO] = {
        shadow: {
            type: 'piano'
        }
    };
    map[ArgumentType.MATRIXICONS] = {
        shadow: {
            type: 'matrix_icons',
            fieldName: 'MATRIX'
        }
    };

    map[ArgumentType.INFRAREDTEXT] = {
        shadow: {
            type: 'text_infrared_btns',
            fieldName: 'TEXT'
        }
    };
    map[ArgumentType.TEXTAREA] = {
        shadow: {
            type: 'textarea'
        }
    };
    map[ArgumentType.BUILTINIMG] = {
        shadow: {
            type: 'builtin_img'
        }
    };
    map[ArgumentType.MQTTPARAMETER] = {
        shadow: {
            type: 'mqtt_settings'
        }
    };
    map[ArgumentType.OBLOQPARAMETER] = {
        shadow: {
            type: 'obloq_settings'
        }
    };
    map[ArgumentType.EVENTHEADINNER] = {
        shadow: {
            fieldName: 'specialBlock_eventHead_origin'
        }
    };
    map[ArgumentType.CAMERALIST] = {
        shadow: {
            type: 'cameralist_menu'
        }
    };
    return map;
})();

const CODE_MODE_MAP = {
    scratch: 0,
    arduino: 1,
    python: 2
};

class DFRuntime extends Runtime {
    constructor (vm) {
        super();
        this.vm = vm;
        // this.__blockInfo = {
        //     'scratch': [], // 角色和背景共用extension
        // }
        this.__primitives = {};
        const handler = {
            set (target, opcode, value) {
                target[replaceDeviceExtensionOpcode(opcode)] = value;
                return true;
            },
            get (target, opcode) {
                return target[replaceDeviceExtensionOpcode(opcode)];
            }
        };
        // 实时模式, 执行方法
        this._primitives = new Proxy(this.__primitives, handler);
        this.__codePrimitives = {};
        // 上传模式, 生成代码方法
        this._codePrimitives = new Proxy(this.__codePrimitives, handler);
        this._registerBlockPackages();

        // cache block input params info
        this.inputParamsInfo = {};

        // web/win 在线版/离线版 默认为离线版
        this.platform = 'win';

        this.ML5 = ml5;
    }

    static get EXTENSION_REMOVED () {
        return 'EXTENSION_REMOVED';
    }

    static get DEVICE_NOT_CONNECTED () {
        return 'DEVICE_NOT_CONNECTED';
    }

    // get _blockInfo () {
    //     if (this._editingTarget && this._editingTarget.isDevice) {
    //         const id = this._editingTarget.id;
    //         if (!this.__blockInfo[id]) {
    //             this.__blockInfo[id] = [];
    //         }
    //         return this.__blockInfo[id];
    //     }
    //     return this.__blockInfo.scratch;
    // }

    // set _blockInfo (data) {
    //     if (this._editingTarget.isDevice) {
    //         this.__blockInfo[this._editingTarget.id] = data;
    //     } else {
    //         this.__blockInfo.scratch = data;
    //     }
    // }

    /*************开放出去的接口 ***************/
    // 获取当前设备名称
    getCurrentDeviceId () {
        return this.deviceManager.getCurrentDeviceId();
    }

    // 创建串口对象
    createSerial () {
        return Promise.reject('createSerial方法需要被重写!!');
    }

    // 获取平台(只能返回web/win/mac/linux等开发环境配置, 如果为web时, 无法判断win/mac/linux系统)
    getPlatform () {
        return this.platform;
    }

    // 当前是否处于浏览器环境
    isWeb() {
        return this.platform === "web";
    }

    // windows系统
    isWin() {
        if (this.platform === "win") return true;
        // 在线版需要判断浏览器的系统信息
        if (this.platform === "web") {
            if (this.navigator&&this.navigator.userAgent.toLowerCase().indexOf("windows") !== -1) {
                return true;
            }
        } 
        return false;
    }
    
    // mac系统
    isMac() {
        if (this.platform === "mac") return true;
        // 在线版需要判断浏览器的系统信息
        if (this.platform === "web") {
            if (this.navigator&&this.navigator.platform.indexOf("Mac") !== -1) {
                return true;
            }
        } 
        return false;
    }

    // linux系统
    isLinux() {
        if (this.platform === "linux") return true;
        // 在线版需要判断浏览器的系统信息
        if (this.platform === "web") {
            if (this.navigator&&this.navigator.platform.indexOf("Linux") !== -1) {
                return true;
            }
        } 
        return false;
    }

    // 获取当前的设备对象(用于小模块扩展中获取)
    getDevice() {
        // 遍历已加载的extension, 找出设备
        let ret = null;
        Array.from(this.vm.extensionManager._loadedExtensions.keys()).forEach(extensionId => {
            if (extensionId.indexOf("dev-") === 0) {
                const serverName = this.vm.extensionManager.getServerName(extensionId);
                const extensionObj = dispatch.services[serverName];
                if (extensionObj&&extensionObj.getDevice) {
                    ret = extensionObj.getDevice();
                }
            }
        });

        return ret;
    }

    // 获取串口的serialNumber
    getSerialNumberByPort(port) {
        throw new Error("function 'getSerialNumberByPort' need rewrite.") 
    }

    // 获取子进程
    getChildProcess() {
        return this.childProcess;
    }

    // 获取fs
    getFS() {
        return this.fs;
    }

    // 推送输出打印信息
    pushOutputMessage(text) {
        this.deviceManager._pushOutputMessage(text)
    }


    /************* end ***************/

    getCodeMode () {
        return this.codeMode;
    }

    getCodeModeIndex () {
        return CODE_MODE_MAP[this.codeMode] || 0;
    }

    // 设置当前模式(SCRATCH/ARDUINO/PYTHON)
    setCodeMode (mode) {
        this.codeMode = mode;
        this.codeModeIndex = CODE_MODE_MAP[mode];
    }

    dispose () {
        super.dispose();

        // //
        // this._editingTarget = null;
    }

    // 非scratch模式, 阻止block运行
    toggleScript (topBlockId, opts) {
        console.log(this.codeMode);
        if (this.codeMode !== 'scratch') return;
        super.toggleScript(topBlockId, opts);
    }

    // 检测workspace中是否有该扩展的block
    workspaceHasExtensionBlock (extensionIdWithVersion) {
        for (const target of this.targets) {
            const blockIds = Object.keys(target.blocks._blocks);
            for (const blockId of blockIds) {
                const blockInfo = target.blocks._blocks[blockId];
                if (!blockInfo) return;
                const opcode = blockInfo.opcode;
                if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                    return true;
                }
            }
        }
        return false;
    }

    // 清除扩展信息
    _clearExtensionPrimitives (extensionIdWithVersion) {
        // 清除categoryInfo
        this._blockInfo = this._blockInfo.filter(info => !(info.id === extensionIdWithVersion || info.id.indexOf(`${extensionIdWithVersion}_`) === 0));
        // 清除执行函数
        for (const opcode in this._primitives) {
            if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                delete this._primitives[opcode];
            }
        }
        for (const opcode in this._codePrimitives) {
            if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                delete this._codePrimitives[opcode];
            }
        }
        // 清除hats
        for (const opcode in this._hats) {
            if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                delete this._hats[opcode];
            }
        }
        // 清除workspace中的block
        this.targets.forEach(target => {
            const blockIds = Object.keys(target.blocks._blocks);
            blockIds.forEach(blockId => {
                const blockInfo = target.blocks._blocks[blockId];
                if (!blockInfo) return;
                const opcode = blockInfo.opcode;
                if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                    console.log('deleteblock!!!');
                    target.blocks.deleteBlock(blockId);
                }
            });
        });

        // toolbox 清除?
        // blockIds = Object.keys(this.flyoutBlocks._blocks);
        // blockIds.forEach(blockId => {
        //     if (blockId.indexOf(`${extensionIdWithVersion}_`) === 0){
        //         console.log("deleteBlock", blockId)
        //         this.flyoutBlocks.deleteBlock(blockId)
        //     }
        // });
        const blockIds = Object.keys(this.monitorBlocks._blocks);
        blockIds.forEach(blockId => {
            const blockInfo = this.monitorBlocks._blocks[blockId];
            if (!blockInfo) return;
            const opcode = blockInfo.opcode;
            if (opcode.indexOf(`${extensionIdWithVersion}_`) === 0) {
                this.monitorBlocks.deleteBlock(blockId);
                // 移除monitor
                this.requestRemoveMonitor(blockId);
            }
        });
        // 触发扩展移除事件
        this.emit(DFRuntime.EXTENSION_REMOVED, extensionIdWithVersion);
    }

    getBlocksXML (target) {
        const ret = this._blockInfo.map(categoryInfo => {
            const {name, color1, color2} = categoryInfo;
            // Filter out blocks that aren't supposed to be shown on this target, as determined by the block info's
            // `hideFromPalette` and `filter` properties.
            const paletteBlocks = categoryInfo.blocks.filter(block => {
                let blockFilterIncludesTarget = true;
                // If an editing target is not passed, include all blocks
                // If the block info doesn't include a `filter` property, always include it
                if (target && block.info.filter) {
                    blockFilterIncludesTarget = block.info.filter.includes(
                        target.isStage ? TargetType.STAGE : TargetType.SPRITE
                    );
                }
                // If the block info's `hideFromPalette` is true, then filter out this block
                return blockFilterIncludesTarget && !block.info.hideFromPalette;
            });

            const colorXML = `colour="${color1}" secondaryColour="${color2}"`;

            // Use a menu icon if there is one. Otherwise, use the block icon. If there's no icon,
            // the category menu will show its default colored circle.
            let menuIconURI = '';
            if (categoryInfo.menuIconURI) {
                menuIconURI = categoryInfo.menuIconURI;
            } else if (categoryInfo.blockIconURI) {
                menuIconURI = categoryInfo.blockIconURI;
            }
            const menuIconXML = menuIconURI ?
                `iconURI="${menuIconURI}"` : '';

            let statusButtonXML = '';
            if (categoryInfo.showStatusButton) {
                statusButtonXML = 'showStatusButton="true"';
            }
            return {
                id: categoryInfo.categoryId || categoryInfo.id,
                xml: `<category name="${name}" id="${categoryInfo.categoryId || categoryInfo.id}" ${statusButtonXML} ${colorXML} ${menuIconXML}>${paletteBlocks.map(block => block.xml).join('')}</category>`
            };
        });
            // 排序, 主板放在前面
        return ret.sort((a, b) => {
            const isDevA = Number(a.id.indexOf('dev-') === 0);
            const isDevB = Number(b.id.indexOf('dev-') === 0);
            return isDevB - isDevA;
        });
    }

    _refreshExtensionPrimitives (extensionInfo) {
        let categoryInfo;
        if (extensionInfo.categoryId) {
            categoryInfo = this._blockInfo.find(info => info.categoryId === extensionInfo.categoryId);
        } else {
            categoryInfo = this._blockInfo.find(info => info.id === extensionInfo.id);
        }
        if (categoryInfo) {
            categoryInfo.name = maybeFormatMessage(extensionInfo.name);
            this._fillExtensionCategory(categoryInfo, extensionInfo);

            this.emit(Runtime.BLOCKSINFO_UPDATE, categoryInfo);
        }
    }
    // categoryInfo增加categoryId属性  生成category时使用
    _registerExtensionPrimitives (extensionInfo) {
        console.log('registerExtensionPrimitives', extensionInfo);
        const categoryInfo = {
            id: extensionInfo.id,
            categoryId: extensionInfo.categoryId,
            name: maybeFormatMessage(extensionInfo.name),
            showStatusButton: extensionInfo.showStatusButton,
            blockIconURI: extensionInfo.blockIconURI,
            menuIconURI: extensionInfo.menuIconURI,
            blockIconWidth: extensionInfo.blockIconWidth,
            blockIconHeight: extensionInfo.blockIconHeight
        };

        if (extensionInfo.color1) {
            categoryInfo.color1 = extensionInfo.color1;
            categoryInfo.color2 = extensionInfo.color2;
            categoryInfo.color3 = extensionInfo.color3;
        } else {
            categoryInfo.color1 = defaultExtensionColors[0];
            categoryInfo.color2 = defaultExtensionColors[1];
            categoryInfo.color3 = defaultExtensionColors[2];
        }
        this._blockInfo.push(categoryInfo);

        this._fillExtensionCategory(categoryInfo, extensionInfo);

        for (const fieldTypeName in categoryInfo.customFieldTypes) {
            if (extensionInfo.customFieldTypes.hasOwnProperty(fieldTypeName)) {
                const fieldTypeInfo = categoryInfo.customFieldTypes[fieldTypeName];

                // Emit events for custom field types from extension
                this.emit(Runtime.EXTENSION_FIELD_ADDED, {
                    name: `field_${fieldTypeInfo.extendedName}`,
                    implementation: fieldTypeInfo.fieldImplementation
                });
            }
        }

        this.emit(Runtime.EXTENSION_ADDED, categoryInfo);
    }

    getBlockArgInfo (type, name) {
        if (this.inputParamsInfo[type] &&
                this.inputParamsInfo[type][name] &&
                this.inputParamsInfo[type][name].inputParams) {
            return this.inputParamsInfo[type][name].inputParams;
        }
        return null;
    }

    /**
         * save field inputparams to inputParamsInfo
         * @param {string} type block opcode
         * @param {string} name field name
         * @param {string} argInfo inputparams
         */
    _cacheInputParamsInfo (type, name, argInfo) {
        const inputParams = argInfo.inputParams;
        if (!inputParams) return;
        if (!this.inputParamsInfo) this.inputParamsInfo = {};
        if (!this.inputParamsInfo[type]) this.inputParamsInfo[type] = {};
        if (!this.inputParamsInfo[type][name]) this.inputParamsInfo[type][name] = {};
        this.inputParamsInfo[type][name].inputParams = Object.assign({}, inputParams);
    }

    /**
         * 清除InputParams
         * @param url 扩展实例
         */
    clearInputParamsInfo (url) {
        if (!this.inputParamsInfo) return;
        const keyArr = [];
        for (const key in this.inputParamsInfo) {
            const tempK = key.slice(0, key.indexOf('.'));
            if (tempK === url) {
                keyArr.push(key);
            }
        }
        keyArr.forEach(idx => {
            delete this.inputParamsInfo[idx];
        });
    }

    clearBlock () {
        this.inputParamsInfo = {};
        this._blockInfo.length = 0;
    }

    // setBlockInfo(targetId, data) {
    //     this.__blockInfo[targetId] = data;
    // }

    _convertBlockForScratchBlocks (blockInfo, categoryInfo) {
        const extendedOpcode = `${categoryInfo.id}_${blockInfo.opcode}`;
        // 如果这个block是可拖动出来的异形block，blockInfo中给定block的名字
        // const extendedOpcode = (blockInfo.isSpecialData && blockInfo.specialDataName) ? blockInfo.specialDataName : `${categoryInfo.id}_${blockInfo.opcode}`;
        const blockJSON = {
            type: extendedOpcode,
            inputsInline: true,
            category: categoryInfo.name,
            colour: categoryInfo.color1,
            colourSecondary: categoryInfo.color2,
            colourTertiary: categoryInfo.color3
        };
        const context = {
            // TODO: store this somewhere so that we can map args appropriately after translation.
            // This maps an arg name to its relative position in the original (usually English) block text.
            // When displaying a block in another language we'll need to run a `replace` action similar to the one
            // below, but each `[ARG]` will need to be replaced with the number in this map.
            argsMap: {},
            blockJSON,
            categoryInfo,
            blockInfo,
            inputList: []
        };

        // If an icon for the extension exists, prepend it to each block, with a vertical separator.
        // We can overspecify an icon for each block, but if no icon exists on a block, fall back to
        // the category block icon.
        const iconURI = blockInfo.blockIconURI || categoryInfo.blockIconURI;

        if (iconURI && !blockInfo.noIcon) {
            blockJSON.extensions = ['scratch_extension'];
            // %2 之后加入一个空格，避免以数字开头的block text 解析bug
            // blockJSON.message0 = '%1 %2';
            blockJSON.message0 = '%1 %2 ';
            const iconJSON = {
                type: 'field_image',
                src: iconURI,
                width: categoryInfo.blockIconWidth || 40,
                height: categoryInfo.blockIconHeight || 40
            };
            const separatorJSON = {
                type: 'field_vertical_separator'
            };
            blockJSON.args0 = [
                iconJSON,
                separatorJSON
            ];
        }

        switch (blockInfo.blockType) {
        case BlockType.COMMAND:
            blockJSON.outputShape = ScratchBlocksConstants.OUTPUT_SHAPE_SQUARE;
            blockJSON.previousStatement = null; // null = available connection; undefined = hat
            if (!blockInfo.isTerminal) {
                blockJSON.nextStatement = null; // null = available connection; undefined = terminal
            }
            break;
        case BlockType.REPORTER:
            blockJSON.output = 'String'; // TODO: distinguish number & string here?
            blockJSON.outputShape = ScratchBlocksConstants.OUTPUT_SHAPE_ROUND;
            break;
        case BlockType.BOOLEAN:
            blockJSON.output = 'Boolean';
            blockJSON.outputShape = ScratchBlocksConstants.OUTPUT_SHAPE_HEXAGONAL;
            break;
        case BlockType.HAT:
        case BlockType.EVENT:
            if (!blockInfo.hasOwnProperty('isEdgeActivated')) {
                // if absent, this property defaults to true
                blockInfo.isEdgeActivated = true;
            }
            blockJSON.outputShape = ScratchBlocksConstants.OUTPUT_SHAPE_SQUARE;
            blockJSON.nextStatement = null; // null = available connection; undefined = terminal
            break;
        case BlockType.CONDITIONAL:
        case BlockType.LOOP:
            blockInfo.branchCount = blockInfo.branchCount || 1;
            blockJSON.outputShape = ScratchBlocksConstants.OUTPUT_SHAPE_SQUARE;
            blockJSON.previousStatement = null; // null = available connection; undefined = hat
            if (!blockInfo.isTerminal) {
                blockJSON.nextStatement = null; // null = available connection; undefined = terminal
            }
            break;
        }

        const blockText = Array.isArray(blockInfo.text) ? blockInfo.text : [blockInfo.text];
        let inTextNum = 0; // text for the next block "arm" is blockText[inTextNum]
        let inBranchNum = 0; // how many branches have we placed into the JSON so far?
        let outLineNum = 0; // used for scratch-blocks `message${outLineNum}` and `args${outLineNum}`
        const convertPlaceholders = this._convertPlaceholders.bind(this, context);
        const extensionMessageContext = this.makeMessageContextForTarget();

        // alternate between a block "arm" with text on it and an open slot for a substack
        while (inTextNum < blockText.length || inBranchNum < blockInfo.branchCount) {
            if (inTextNum < blockText.length) {
                context.outLineNum = outLineNum;
                const lineText = maybeFormatMessage(blockText[inTextNum], extensionMessageContext);
                const convertedText = lineText.replace(/\[(.+?)]/g, convertPlaceholders);
                if (blockJSON[`message${outLineNum}`]) {
                    blockJSON[`message${outLineNum}`] += convertedText;
                } else {
                    blockJSON[`message${outLineNum}`] = convertedText;
                }
                ++inTextNum;
                ++outLineNum;
            }
            if (inBranchNum < blockInfo.branchCount) {
                blockJSON[`message${outLineNum}`] = '%1';
                blockJSON[`args${outLineNum}`] = [{
                    type: 'input_statement',
                    name: `SUBSTACK${inBranchNum > 0 ? inBranchNum + 1 : ''}`
                }];
                ++inBranchNum;
                ++outLineNum;
            }
        }

        if (blockInfo.blockType === BlockType.REPORTER) {
            blockJSON.checkboxInFlyout = blockInfo.checkboxInFlyout || false;
            // if (!blockInfo.disableMonitor && context.inputList.length === 0) {
            //     blockJSON.checkboxInFlyout = true;
            // }
        } else if (blockInfo.blockType === BlockType.LOOP) {
            // Add icon to the bottom right of a loop block
            blockJSON[`lastDummyAlign${outLineNum}`] = 'RIGHT';
            blockJSON[`message${outLineNum}`] = '%1';
            blockJSON[`args${outLineNum}`] = [{
                type: 'field_image',
                src: './static/blocks-media/repeat.svg', // TODO: use a constant or make this configurable?
                width: 24,
                height: 24,
                alt: '*', // TODO remove this since we don't use collapsed blocks in scratch
                flip_rtl: true
            }];
            ++outLineNum;
        }

        const mutation = blockInfo.isDynamic ? `<mutation blockInfo="${xmlEscape(JSON.stringify(blockInfo))}"/>` : '';
        const inputs = context.inputList.join('');
        const blockXML = `<block
            placeInMore="${blockInfo.placeInMore ? 'true' : ''}"
            submenuId="${blockInfo.submenuId ? blockInfo.submenuId : ''}"
            group="${blockInfo.group ? blockInfo.group : ''}"
            disabled="${this.vm.extensionManager.checkDisabledByBlockInfo(blockInfo)}"
            type="${extendedOpcode}">
            ${mutation}${inputs}
        </block>`;
        return {
            info: context.blockInfo,
            json: context.blockJSON,
            xml: blockXML
        };
    }

    _convertForScratchBlocks (blockInfo, categoryInfo) {
        if (blockInfo === '---') {
            return this._convertSeparatorForScratchBlocks(blockInfo);
        }
        if (blockInfo.blockType === BlockType.SUBMENU) {
            return this.convertSubMenuForScratchBlocks(blockInfo);
        }

        if (blockInfo.blockType === BlockType.BUTTON) {
            return this._convertButtonForScratchBlocks(blockInfo);
        }

        return this._convertBlockForScratchBlocks(blockInfo, categoryInfo);
    }

    convertSubMenuForScratchBlocks (blockInfo) {
        return {
            info: blockInfo,
            xml: `<submenu id="${blockInfo.id}" text="${blockInfo.text}"></submenu>`
        };
    }

    disposeTarget (disposingTarget) {
        this.targets = this.targets.filter(target => {
            if (disposingTarget !== target) return true;
            // Allow target to do dispose actions.
            target.dispose();
            // Remove from list of targets.
            return false;
        });
    }

    getLabelForOpcode (extendedOpcode) {
        const category = getExtensionInstanceId(extendedOpcode);
        const opcode = getRawOpcode(extendedOpcode);
        if (!(category && opcode)) return;

        const categoryInfo = this._blockInfo.find(ci => ci.id === category);
        if (!categoryInfo) return;

        const block = categoryInfo.blocks.find(b => b.info.opcode === opcode);
        if (!block) return;

        // TODO: we may want to format the label in a locale-specific way.
        return {
            category: 'extension', // This assumes that all extensions have the same monitor color.
            label: `${categoryInfo.name}: ${block.info.text}`,
            categoryPrimaryColor: categoryInfo.color1
        };
    }

    // 只切换editingTaget数据, 不渲染
    setEditingTargetData (editingTarget) {
        this._editingTarget = editingTarget;
    }

    setEditingTarget (editingTarget) {
        const oldEditingTarget = this._editingTarget;
        this._editingTarget = editingTarget;
        // Script glows must be cleared.
        this._scriptGlowsPreviousFrame = [];
        this._updateGlows();

        if (oldEditingTarget !== this._editingTarget) {
            this.requestToolboxExtensionsUpdate();
        }
    }

    // 增加: 阻止上传模式下的block运行
    startHats (requestedHatOpcode,
        optMatchFields, optTarget) {
        if (!this._hats.hasOwnProperty(requestedHatOpcode)) {
            // No known hat with this opcode.
            return;
        }
        const instance = this;
        const newThreads = [];
        // Look up metadata for the relevant hat.
        const hatMeta = instance._hats[requestedHatOpcode];

        for (const opts in optMatchFields) {
            if (!optMatchFields.hasOwnProperty(opts)) continue;
            optMatchFields[opts] = optMatchFields[opts].toUpperCase();
        }

        // Consider all scripts, looking for hats with opcode `requestedHatOpcode`.
        this.allScriptsByOpcodeDo(requestedHatOpcode, (script, target) => {
            const {
                blockId: topBlockId,
                fieldsOfInputs: hatFields
            } = script;

            // Match any requested fields.
            // For example: ensures that broadcasts match.
            // This needs to happen before the block is evaluated
            // (i.e., before the predicate can be run) because "broadcast and wait"
            // needs to have a precise collection of started threads.
            for (const matchField in optMatchFields) {
                if (hatFields[matchField].value !== optMatchFields[matchField]) {
                    // Field mismatch.
                    return;
                }
            }

            if (hatMeta.restartExistingThreads) {
                // If `restartExistingThreads` is true, we should stop
                // any existing threads starting with the top block.
                for (let i = 0; i < this.threads.length; i++) {
                    if (this.threads[i].target === target &&
                            this.threads[i].topBlock === topBlockId &&
                            // stack click threads and hat threads can coexist
                            !this.threads[i].stackClick) {
                        newThreads.push(this._restartThread(this.threads[i]));
                        return;
                    }
                }
            } else {
                // If `restartExistingThreads` is false, we should
                // give up if any threads with the top block are running.
                for (let j = 0; j < this.threads.length; j++) {
                    if (this.threads[j].target === target &&
                            this.threads[j].topBlock === topBlockId &&
                            // stack click threads and hat threads can coexist
                            !this.threads[j].stackClick &&
                            this.threads[j].status !== Thread.STATUS_DONE) {
                        // Some thread is already running.
                        return;
                    }
                }
            }
            // Start the thread with this top block.
            newThreads.push(this._pushThread(topBlockId, target));
        }, optTarget);
        // For compatibility with Scratch 2, edge triggered hats need to be processed before
        // threads are stepped. See ScratchRuntime.as for original implementation
        const newThreads_ = newThreads.filter(thread => {
            let ret = true;
            // 阻止上传模式的block运行
            if (thread.target && !this.deviceManager.isScratchMode(thread.target.id)) {
                ret = false;
            } else {
                execute(this.sequencer, thread);
                thread.goToNextBlock();
            }
            return ret;
        });
        return newThreads_;
    }

    // 增加: 注册上传模式的代码生成方法
    _fillExtensionCategory (categoryInfo, extensionInfo) {
        categoryInfo.blocks = [];
        categoryInfo.customFieldTypes = {};
        categoryInfo.menus = [];
        categoryInfo.menuInfo = {};


        for (const menuName in extensionInfo.menus) {
            if (extensionInfo.menus.hasOwnProperty(menuName)) {
                const menuInfo = extensionInfo.menus[menuName];
                const convertedMenu = this._buildMenuForScratchBlocks(menuName, menuInfo, categoryInfo);
                categoryInfo.menus.push(convertedMenu);
                categoryInfo.menuInfo[menuName] = menuInfo;
            }
        }
        for (const fieldTypeName in extensionInfo.customFieldTypes) {
            if (extensionInfo.customFieldTypes.hasOwnProperty(fieldTypeName)) {
                const fieldType = extensionInfo.customFieldTypes[fieldTypeName];
                const fieldTypeInfo = this._buildCustomFieldInfo(
                    fieldTypeName,
                    fieldType,
                    extensionInfo.id,
                    categoryInfo
                );

                categoryInfo.customFieldTypes[fieldTypeName] = fieldTypeInfo;
            }
        }

        for (const blockInfo of extensionInfo.blocks) {
            try {
                const convertedBlock = this._convertForScratchBlocks(blockInfo, categoryInfo);
                categoryInfo.blocks.push(convertedBlock);
                if (convertedBlock.json) {
                    const opcode = convertedBlock.json.type;
                    if (blockInfo.blockType !== BlockType.EVENT) {
                        this._primitives[opcode] = convertedBlock.info.func;
                        if (convertedBlock.info.codeFunc) this._codePrimitives[opcode] = convertedBlock.info.codeFunc;
                    }
                    if (blockInfo.blockType === BlockType.EVENT || blockInfo.blockType === BlockType.HAT) {
                        this._hats[opcode] = {
                            edgeActivated: blockInfo.isEdgeActivated,
                            restartExistingThreads: blockInfo.shouldRestartExistingThreads
                        };
                    }
                }
            } catch (e) {
                console.error('Error parsing block: ', {block: blockInfo, error: e});
            }
        }
    }

    // 增加:
    // 1.control/data...等block的生成代码
    _registerBlockPackages () {
        for (const packageName in defaultBlockPackages) {
            if (defaultBlockPackages.hasOwnProperty(packageName)) {
                // @todo pass a different runtime depending on package privilege?
                const packageObject = new (defaultBlockPackages[packageName])(this);
                // Collect primitives from package.
                if (packageObject.getPrimitives) {
                    const packagePrimitives = packageObject.getPrimitives();
                    for (const op in packagePrimitives) {
                        if (packagePrimitives.hasOwnProperty(op)) {
                            this._primitives[op] =
                                    packagePrimitives[op].bind(packageObject);
                        }
                    }
                }
                if (packageObject.getCodePrimitives) {
                    const packageCodePrimitives = packageObject.getCodePrimitives();
                    for (const op in packageCodePrimitives) {
                        if (packageCodePrimitives.hasOwnProperty(op)) {
                            this._codePrimitives[op] =
                                    packageCodePrimitives[op].bind(packageObject);
                        }
                    }
                }
                // Collect hat metadata from package.
                if (packageObject.getHats) {
                    const packageHats = packageObject.getHats();
                    for (const hatName in packageHats) {
                        if (packageHats.hasOwnProperty(hatName)) {
                            this._hats[hatName] = packageHats[hatName];
                        }
                    }
                }
                // Collect monitored from package.
                if (packageObject.getMonitored) {
                    this.monitorBlockInfo = Object.assign({}, this.monitorBlockInfo, packageObject.getMonitored());
                }
            }
        }
    }

    /**
         * Helper for _convertForScratchBlocks which handles linearization of argument placeholders. Called as a callback
         * from string#replace. In addition to the return value the JSON and XML items in the context will be filled.
         * @param {object} context - information shared with _convertForScratchBlocks about the block, etc.
         * @param {string} match - the overall string matched by the placeholder regex, including brackets: '[FOO]'.
         * @param {string} placeholder - the name of the placeholder being matched: 'FOO'.
         * @return {string} scratch-blocks placeholder for the argument: '%1'.
         * @private
         */
    _convertPlaceholders (context, match, placeholder) {
        // Sanitize the placeholder to ensure valid XML
        placeholder = placeholder.replace(/[<"&]/, '_');

        // Determine whether the argument type is one of the known standard field types
        const argInfo = context.blockInfo.arguments[placeholder] || {};

        let argTypeInfo = ArgumentTypeMap[argInfo.type] || {};
        // Field type not a standard field type, see if extension has registered custom field type
        if (context.categoryInfo.customFieldTypes[argInfo.type]) {
            argTypeInfo = context.categoryInfo.customFieldTypes[argInfo.type].argumentTypeInfo;
        }
        const blockType = context.blockInfo.opcode;
        const extensionId = context.categoryInfo.id;

        if (argInfo.type === ArgumentType.EVENTHEADINNER) {
            if (argInfo.inputParams.parent) {
                argInfo.inputParams.parent = `${extensionId}_${blockType}`;
                argInfo.inputParams.block = `${extensionId}_${argInfo.inputParams.block}`;
            }
        }
        const defaultValue =
                typeof argInfo.defaultValue === 'undefined' ? '' :
                    xmlEscape(maybeFormatMessage(argInfo.defaultValue, this.makeMessageContextForTarget()).toString());
            // cache inputParamsInfo
        this._cacheInputParamsInfo(`${extensionId}_${blockType}`, placeholder, argInfo);

        if (argInfo.type === ArgumentType.EVENTHEADINNER) {
            const mutation = context.inputList[0];
            const isMutation = mutation ? mutation.indexOf('mutation') : -1;
            if (isMutation !== -1) {
                let proccode = mutation.substring(mutation.indexOf('proccode="') + 10,
                    mutation.indexOf('"', (mutation.indexOf('proccode="') + 10)));
                let argumentids = mutation.substring(mutation.indexOf('argumentids="') + 13,
                    mutation.indexOf('"', (mutation.indexOf('argumentids="') + 12)));
                let argumentnames = mutation.substring(mutation.indexOf('argumentnames="') + 15,
                    mutation.indexOf('"', (mutation.indexOf('argumentnames="') + 14)));
                proccode += argInfo.specialType === 'bool' ? ' %b' : ' %s';
                argumentids = `[&quot;${uid()}&quot;]`;
                argumentnames = `[&quot;${argInfo.name}&quot;]`;
                const newMutation = `<mutation xmlns="http://www.w3.org/1999/xhtml" generateShadows="true" proccode="${proccode}" argumentids="${argumentids}" argumentnames="${argumentnames}" warp="true"></mutation>`;
                context.inputList[0] = newMutation;
            } else {
                const proccode = argInfo.specialType === 'bool' ? ' %b' : ' %s';
                const argumentids = `[&quot;${uid()}&quot;]`;
                const argumentnames = `[&quot;${argInfo.name}&quot;]`;
                const newMutation = `<mutation xmlns="http://www.w3.org/1999/xhtml" generateShadows="true" proccode="${proccode}" argumentids="${argumentids}" argumentnames="${argumentnames}" warp="true"></mutation>`;
                context.inputList.unshift(newMutation);
            }
            context.inputList.push(`<value name="${placeholder}">`);
            if (!argInfo.inputParams.block) {
                throw new Error('event head block should defined params');
            }
            context.inputList.push(`<shadow type="string">`);
            context.inputList.push(`<field name="${argInfo.name}">${defaultValue}</field>`);
            context.inputList.push('</shadow>');
            context.inputList.push(`<block type="${argInfo.inputParams.block}"></block>`);
            context.inputList.push('</value>');
            const argsName = `args${context.outLineNum}`;
            const blockArgs = (context.blockJSON[argsName] = context.blockJSON[argsName] || []);
            const argJSON = {
                type: 'input_value',
                name: placeholder
            };
            blockArgs.push(argJSON);
            const argNum = blockArgs.length;
            context.argsMap[placeholder] = argNum;
            return `%${argNum}`;
        }


        // Start to construct the scratch-blocks style JSON defining how the block should be
        // laid out
        let argJSON;

        // Most field types are inputs (slots on the block that can have other blocks plugged into them)
        // check if this is not one of those cases. E.g. an inline image on a block.
        if (argTypeInfo.fieldType === 'field_image') {
            argJSON = this._constructInlineImageJson(argInfo);
        } else {
            // Construct input value
            // Layout a block argument (e.g. an input slot on the block)
            argJSON = {
                type: 'input_value',
                name: placeholder
            };
            const defaultValue =
                    typeof argInfo.defaultValue === 'undefined' ? '' :
                        xmlEscape(maybeFormatMessage(argInfo.defaultValue, this.makeMessageContextForTarget()).toString());

            if (argTypeInfo.check) {
                // Right now the only type of 'check' we have specifies that the
                // input slot on the block accepts Boolean reporters, so it should be
                // shaped like a hexagon
                argJSON.check = argTypeInfo.check;
            }
            // 兼容 arguments 配置有fieldType的情况
            if (argInfo.fieldType) {
                argJSON.type = argInfo.fieldType;
                for (const key in argInfo) {
                    if (key !== 'fieldType' && key !== 'type') {
                        argJSON[key] = argInfo[key];
                    }
                }
                const argsName = `args${context.outLineNum}`;
                const blockArgs = (context.blockJSON[argsName] = context.blockJSON[argsName] || []);
                blockArgs.push(argJSON);
                const argNum = blockArgs.length;
                context.argsMap[placeholder] = argNum;
                return `%${argNum}`;
            }

            let valueName;
            let shadowType;
            let fieldName;
            if (argInfo.menu) {
                const menuInfo = context.categoryInfo.menuInfo[argInfo.menu];
                if (menuInfo.acceptReporters) {
                    valueName = placeholder;
                    shadowType = this._makeExtensionMenuId(argInfo.menu, context.categoryInfo.id);
                    fieldName = argInfo.menu;
                } else {
                    argJSON.type = 'field_dropdown';
                    argJSON.options = this._convertMenuItems(menuInfo.items);
                    valueName = null;
                    shadowType = null;
                    fieldName = placeholder;
                }
            } else {
                valueName = placeholder;
                shadowType = (argTypeInfo.shadow && argTypeInfo.shadow.type) || null;
                fieldName = (argTypeInfo.shadow && argTypeInfo.shadow.fieldName) || null;
            }
            // <value> is the ScratchBlocks name for a block input.
            if (valueName) {
                context.inputList.push(`<value name="${placeholder}">`);
            }

            // The <shadow> is a placeholder for a reporter and is visible when there's no reporter in this input.
            // Boolean inputs don't need to specify a shadow in the XML.
            if (shadowType) {
                context.inputList.push(`<shadow type="${shadowType}">`);
            }

            // A <field> displays a dynamic value: a user-editable text field, a drop-down menu, etc.
            // Leave out the field if defaultValue or fieldName are not specified
            if (defaultValue && fieldName) {
                context.inputList.push(`<field name="${fieldName}">${defaultValue}</field>`);
            }

            if (shadowType) {
                context.inputList.push('</shadow>');
            }

            if (valueName) {
                context.inputList.push('</value>');
            }
        }

        const argsName = `args${context.outLineNum}`;
        const blockArgs = (context.blockJSON[argsName] = context.blockJSON[argsName] || []);
        if (argJSON) blockArgs.push(argJSON);
        const argNum = blockArgs.length;
        context.argsMap[placeholder] = argNum;
        return `%${argNum}`;
    }

    // 获取生成代码方法
    getCodeOpcodeFunction (opcode) {
        return this._codePrimitives[opcode];
    }

    extensionStartHats (requestedHatOpcode, optMatchFields, optTarget) {
        if (!this._hats.hasOwnProperty(requestedHatOpcode)) {
            // No known hat with this opcode.
            return;
        }
        const instance = this;
        const newThreads = [];
        for (const opts in optMatchFields) {
            if (!optMatchFields.hasOwnProperty(opts)) continue;
            optMatchFields[opts] = optMatchFields[opts].toUpperCase();
        }

        // Consider all scripts, looking for hats with opcode `requestedHatOpcode`.
        this.allScriptsDo((topBlockId, target) => {
            const blocks = target.blocks;
            const block = blocks.getBlock(topBlockId);

            // let menuB = block.getInputTargetBlock('REFERENCE');
            const potentialHatOpcode = block.opcode;
            if (potentialHatOpcode !== requestedHatOpcode) {
                // Not the right hat.
                return;
            }

            // Match any requested fields.
            // For example: ensures that broadcasts match.
            // This needs to happen before the block is evaluated
            // (i.e., before the predicate can be run) because "broadcast and wait"
            // needs to have a precise collection of started threads.
            let hatFields = blocks.getFields(block);
            // If no fields are present, check inputs (horizontal blocks)
            if (Object.keys(hatFields).length === 0) {
                hatFields = {}; // don't overwrite the block's actual fields list
                const hatInputs = blocks.getInputs(block);
                for (const input in hatInputs) {
                    if (!hatInputs.hasOwnProperty(input)) continue;
                    const id = hatInputs[input].block;
                    const inpBlock = blocks.getBlock(id);
                    const fields = blocks.getFields(inpBlock);
                    Object.assign(hatFields, fields);
                }
            }

            if (optMatchFields) {
                for (const matchField in optMatchFields) {
                    if (hatFields[matchField] && hatFields[matchField].value.toUpperCase() !==
                            optMatchFields[matchField]) {
                        // Field mismatch.
                        return;
                    }
                }
            }

            // Look up metadata for the relevant hat.
            const hatMeta = instance._hats[requestedHatOpcode];
            if (hatMeta.restartExistingThreads) {
                // If `restartExistingThreads` is true, we should stop
                // any existing threads starting with the top block.
                for (let i = 0; i < instance.threads.length; i++) {
                    if (instance.threads[i].topBlock === topBlockId && !instance.threads[i].stackClick && // stack click threads and hat threads can coexist
                            instance.threads[i].target === target) {
                        newThreads.push(instance._restartThread(instance.threads[i]));
                        return;
                    }
                }
            } else {
                // If `restartExistingThreads` is false, we should
                // give up if any threads with the top block are running.
                for (let j = 0; j < instance.threads.length; j++) {
                    if (instance.threads[j].topBlock === topBlockId && instance.threads[j].target === target && !instance.threads[j].stackClick && // stack click threads and hat threads can coexist
                            instance.threads[j].status !== Thread.STATUS_DONE) {
                        // Some thread is already running.
                        return;
                    }
                }
            }
            // Start the thread with this top block.
            newThreads.push(instance._pushThread(topBlockId, target));
        }, optTarget);
        return newThreads;
    }

    attachMethod (key, value) {
        this[key] = value;
    }

    detachMethod (key) {
        if (this[key]) {
            if (this[key].dispose instanceof Function) this[key].dispose();
            if (this[key].destory instanceof Function) this[key].destory();
            delete this[key];
        }
    }


}

module.exports = DFRuntime;
