import { vm } from "lib/scratch-vm";
import AudioEngine from "scratch-audio";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIntl } from "redux/intl/intlSlice";
import { selectMode } from "redux/mode/modeSlice";
import { selectVmStatus } from "redux/vm-status/vmStatusSlice";
import ExtAssetManager from "service/ext-asset-manager/extAssetManager";
import fs from "service/link-adapter/fs";
import os from "service/link-adapter/os";
import path from "service/link-adapter/path";
import Serialport from "service/link-adapter/serialport";
import { DeviceManager } from "service/device-manager/deviceManager";
import alertDialog from "component/df-alert/DFAlert";
// import { Translate } from 'lib/scratch-l10n'
import Udp from "service/link-adapter/udp";
import * as ChildProcess from "service/link-adapter/child_process";
import {IS_SCRATCH_MODE, PLATFORM} from 'config/config'
import { getBasePathConfig } from "service/path/base";
import nodePath from "path";
import Mqtt from "service/link-adapter/mqtt";
import { activeDeck } from "redux/card/cardSlice";

function createSerial(port: string, options: any) {
  return new Serialport(port, options);
}

function createDgram() {
  return new Udp();
}

function createMqtt() { 
  return new Mqtt()
}

const getPathWithHomedir = (path_: string) => {
  const BASE_PATH_CONFIG = getBasePathConfig();
  return nodePath.join(BASE_PATH_CONFIG.HOMEDIR, path_);
};

/**
 * @Description: vm初始化,包含挂载音频，设置翻译，启动
 * @author LiuSheng
 * @date 2023/5/4
 */
export const useVmManager = () => {
  const intl = useSelector(selectIntl);
  const { isPlayerOnly } = useSelector(selectMode);
  const { started } = useSelector(selectVmStatus);
  const dispatch = useDispatch()

  //vm未初始化,挂载音频引擎、设置2.0兼容模式
  useEffect(() => {
    if (!vm.initialized) {
      vm.attachAudioEngine(new AudioEngine());
      vm.setCompatibilityMode(true);
      
      vm.attachDeviceManager(new DeviceManager(vm));
      vm.runtime.createSerial = createSerial;
      vm.runtime.dfrobotAlert = alertDialog;
      vm.runtime.fs = fs;
      vm.runtime.path = path;
      vm.runtime.os = os;
      vm.runtime.childProcess = ChildProcess;
      // 用于判断浏览器所处系统环境(win/mac/linux)
      vm.runtime.navigator = window.navigator;
      // 设置平台(在线版or离线版)
      vm.runtime.platform = PLATFORM;
      vm.runtime.getPathWithHomedir = getPathWithHomedir;
      vm.runtime.SerialPort = Serialport;
      if (IS_SCRATCH_MODE) {
        vm.runtime.createMqtt = createMqtt;
        // udp
        vm.runtime.createDgram = createDgram;
      }
      
      const activateDeck = (id: string) => {
        dispatch(activeDeck(id))
      }
      vm.runtime.activateDeck = activateDeck;

      vm.initialized = true;
    }
  }, []);

  //vm设置地区、翻译资源
  useEffect(() => {
    vm.setLocale(intl.locale, intl.messages);
  }, [intl.locale, intl.messages]);

  // 挂载设备/扩展资源的相关方法
  useEffect(() => {
    vm.runtime.extAssetManager = new ExtAssetManager(vm);
    return () => {
      vm.runtime.extAssetManager = null;
    };
  }, []);

  //vm启动
  useEffect(() => {
    if (!isPlayerOnly && !started && IS_SCRATCH_MODE) {
      console.log("vm 启动");
      vm.start();
    }
  }, [isPlayerOnly, started]);
};
