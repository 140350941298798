// @flow
import * as React from 'react';
import { useDispatch } from "react-redux";
import DFDropdown from "component/df-dropdown/DFDropdown";
import message from "../../../component/df-message/Message";
import DownArrowIcon from "asset/image/down_arrow_icon.svg"
import styles from "../MenuBar.module.scss"
import { openTipsLibrary } from 'redux/asset-lib/assetLibStatusSlice';

export const Lesson = () => {
  const dispatch = useDispatch();
  const handleOpenTipsLib = () => {
    dispatch(openTipsLibrary());
  }
  const onDropdownClick = ({ key }) => {
    switch (key) {
      case '1':
        handleOpenOfficeDocument()
        break
      case '2':
        handleOnlineForum()
        break
      case '3':
        handleOpenTipsLib();
        break
      default:
        message.error("暂未实现!")
        break
    }

  }

  const handleOpenOfficeDocument = () => {
    window.open('https://mindplus.dfrobot.com.cn/', '_blank')
  }

  const handleOnlineForum = () => {
    window.open('http://mc.dfrobot.com.cn/forum-165-1.html', '_blank')
  }

  const items = [
    {
      key: '1',
      label: '官方文档',
    },
    {
      key: '2',
      label: '在线论坛',
    },
    {
      key: '3',
      label: '视频教程',
    },
    {
      key: '4',
      label: '示例程序',
    },
  ];
  return (
    <DFDropdown trigger={'click'} items={items} onItemClick={onDropdownClick} width={'100'}>
      <div className={styles.dropdownContent}>
        <span >教程</span>
        <img src={DownArrowIcon} />
      </div>
    </DFDropdown>
  );
};
