const mainProgramHeader = [
    'maixduino.maixduino_functions',
    'romeo\.romeo_programstart',
    'arduino\.arduino_functions',
    'vortex\.vortexSetUp',
    'microbit\.microbit_functions',
    'maqueen\.maqueenStarts',
    'leonardo\.leonardo_functions',
    'arduinounor3\.arduinounoR3_functions',
    'arduinonano\.arduinoNano_functions',
    'maxbot\.microbit_functions',
    'max\.maxStarts',
    'mega2560.mega2560_functions',
    'esp8266.esp8266_functions',
    'esp32.esp32_functions',
    'telloesp32.telloesp32_functions',
    'firebeetleesp32.firebeetle_functions',
    'boson.boson_functions',
    'pico.pico_functions',
    'fireBeetleEsp32E.fireBeetleEsp32E_functions',
    'multithread.threadsCode',
    'pythonBase_main_functions'
];


const fieldsMapping = {
    math_range: {
        opcode: 'math_slider'
    },
    esp32_text_preview: {
        opcode: 'text_preview'
    },
    esp32_img_settings: {
        opcode: 'img_preview'
    },
    oled2864_img_settings: {
        opcode: 'img_preview'
    },
    oled2864_text_preview: {
        opcode: 'text_preview'
    },
    microbit_piano: {
        opcode: 'piano'
    },
    colour_palette: {
        opcode: 'colour_palette'
    },
    mpy_show_img: {
        opcode: 'builtin_img'
    },
    content_input: {
        opcode: 'textarea'
    },
    pictureai_img_setting: obj => {
        obj.opcode = 'img_setting';
        return obj;
    },
    pictureai_dir_setting: obj => {
        if (obj.fields && obj.fields.pictureai_dir) {
            obj.fields.pictureai_dir.value = JSON.parse(obj.fields.pictureai_dir.value).imgAddr;
        }
        obj.opcode = 'dir_setting';
        return obj;
    },
    general_userserver: obj => {
        // TODO:  原本是从globalConfig中读取 ,暂时设置为空
        if (obj.fields && obj.fields.general_userserver) {
            obj.fields.general_userserver.name = 'FORM_SETTINGS';
            obj.fields.general_userserver.value = JSON.stringify({key1: '', key2: ''});
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    pictureai_webimg_setting: {
        opcode: 'online_img_setting'
    },
    stepper_motor_axis_setting: obj => {
        if (obj.fields && obj.fields.MOTOR) {
            obj.fields.MOTOR.name = 'FORM_SETTINGS';
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    pictureai_userserver: obj => {
        // TODO:  原本是从globalConfig中读取 ,暂时设置为空
        if (obj.fields && obj.fields.pictureai_userserver) {
            obj.fields.pictureai_userserver.name = 'FORM_SETTINGS';
            obj.fields.pictureai_userserver.value = JSON.stringify({key1: '', key2: ''});
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    tinydb_settings: obj => {
        if (obj.fields && obj.fields.OBLOQ) {
            obj.fields.OBLOQ.name = 'FORM_SETTINGS';
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    obloq_initial_http_parameter: obj => {
        if (obj.fields && obj.fields.OBLOQ && obj.fields.OBLOQ.value) {
            const value = JSON.parse(obj.fields.OBLOQ.value);
            const newValue = {
                username: value.wifi.username,
                pwd: value.wifi.pwd,
                ip: value.http.ip,
                port: value.http.port
            };
            obj.fields.OBLOQ.name = 'FORM_SETTINGS';
            obj.fields.OBLOQ.value = JSON.stringify(newValue);
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    settings: obj => {
        if (obj.fields && obj.fields.SETTINGS) {
            obj.fields.SETTINGS.name = 'FORM_SETTINGS';
        }
        obj.opcode = 'form_settings';
        return obj;
    },
    obloq_http_settings: obj => {
        if (obj.fields && obj.fields.OBLOQ && obj.fields.OBLOQ.value) {
            const value = JSON.parse(obj.fields.OBLOQ.value);
            const newValue = {
                username: value.wifi.username,
                pwd: value.wifi.pwd,
                ip: value.http.ip,
                port: value.http.port
            };
            obj.fields.OBLOQ.value = JSON.stringify(newValue);
        }
        obj.fields.OBLOQ.name = 'FORM_SETTINGS';
        obj.opcode = 'form_settings';
        return obj;
    },
    steppermotor_axis_settings: obj => {
        if (obj.fields && obj.fields.MOTOR) {
            obj.fields.MOTOR.name = 'FORM_SETTINGS';
        }
        obj.opcode = 'form_settings';
        return obj;
    }
};


const transformCoreExtensionId = (opcode, newExtensionId) => {
    const regex = /^(\w+)\.menu\.(\w+)$/; // 匹配形如 "xxx.menu.yyy" 的字符串
    const match = opcode.match(regex);
    if (match) {
        const [, , menuName] = match;
        return `${newExtensionId}_menu_${menuName}`;
    }
    // 处理 opcode 前缀
    const prefixEndIndex = opcode.indexOf('.') + 1;
    if (prefixEndIndex !== 0) return opcode.replace(opcode.substring(0, prefixEndIndex), `${newExtensionId}_`);
    return opcode;
};

const transFromOpcode = (opcode, config) => {
    const prefix = config.isDevice ? 'dev-' : 'ext-';
    const author = config.author;
    const version = config.version;
    const newExtensionId = config.id;
    const regex = /^(\w+)\.menu\.(\w+)$/; // 匹配形如 "xxx.menu.yyy" 的字符串
    const match = opcode.match(regex);
    if (match) {
        const [, , menuName] = match;
        return `${prefix}${author}-${newExtensionId}@${version}_menu_${menuName}`;
    }
    // 处理 opcode 前缀
    const prefixEndIndex = opcode.indexOf('.') + 1;
    if (prefixEndIndex !== 0) return opcode.replace(opcode.substring(0, prefixEndIndex), `${prefix}${author}-${newExtensionId}@${version}_`);
    return opcode;
};


const CompatibilityModule = {
    // 内置block的兼容转换
    compatibleBuiltInBlocks: function (oldObject) {
        const newObject = {...oldObject};
        const opcode = oldObject.opcode;
        // TODO: 代码需要优化
        if (opcode === 'control_if_else_special') {
            let elseIfCount = 0;
            let elseCount = 0;
            if (oldObject.inputs) {
                // IF0 DO0 部分
                if (oldObject.inputs.hasOwnProperty('CONDITION') && oldObject.inputs.hasOwnProperty('SUBSTACK')) {
                    newObject.inputs = {};
                    newObject.inputs.IF0 = oldObject.inputs.CONDITION;
                    newObject.inputs.IF0.name = 'IF0';
                    newObject.inputs.DO0 = oldObject.inputs.SUBSTACK;
                    newObject.inputs.DO0.name = 'DO0';
                }
                let index = 2;
                while (oldObject.inputs.hasOwnProperty(`CONDITION${index}`) && oldObject.inputs.hasOwnProperty(`SUBSTACK${index}`)) {
                    elseIfCount++;
                    newObject.inputs[`IF${index - 1}`] = oldObject.inputs[`CONDITION${index}`];
                    newObject.inputs[`IF${index - 1}`].name = `IF${index - 1}`;
                    newObject.inputs[`DO${index - 1}`] = oldObject.inputs[`SUBSTACK${index}`];
                    newObject.inputs[`DO${index - 1}`].name = `DO${index - 1}`;
                    index++;
                }
                // ELSE部分
                if (oldObject.inputs.hasOwnProperty(`SUBSTACK${index}`)) {
                    newObject.inputs[`DO${index - 1}`] = {
                        name: `DO${index - 1}`,
                        block: null,
                        shadow: null
                    };
                    newObject.inputs.ELSE = oldObject.inputs[`SUBSTACK${index}`];
                    newObject.inputs.ELSE.name = 'ELSE';
                    elseCount = 1;
                }
            }
            // 获取原本的elseIf数量
            const originElseIfCount = parseInt(oldObject.count, 10) - 2;
            // mind+ 旧版逻辑是空的elseIf也算1个elseIf
            if (originElseIfCount > elseIfCount) {
                elseIfCount = originElseIfCount;
            }
            newObject.mutation = {
                children: [],
                else: elseCount.toString(),
                elseif: elseIfCount.toString(),
                tagName: 'mutation'
            };
        }
        if (opcode === 'control_loop') {
            newObject.opcode = 'control_forever';
            return newObject;
        }
        return newObject;
    },

    compatibleOpcode: function (oldObject, configList) {
        const newObject = {...oldObject};
        const opcode = oldObject.opcode;
        if (fieldsMapping.hasOwnProperty(opcode)) {
            if (typeof fieldsMapping[opcode] === 'function') {
                return fieldsMapping[opcode](newObject);
            }
            newObject.opcode = fieldsMapping[opcode].opcode;
            return newObject;
        }
        for (let i = 0; i < configList.length; i++) {
            const config = configList[i];
            const extensionId = opcode.substring(0, opcode.indexOf('.'));
            // scratch 内置扩展的兼容处理
            if (config.oldId === extensionId) {
                newObject.opcode = transformCoreExtensionId(opcode, config.newId);
                return newObject;
            }
            // TODO: 之后可以在此处理有多个config的compatiblityId等于extensionId的情况  当前假设只有一个
            if (config.compatiblityId === extensionId) {
                newObject.opcode = transFromOpcode(opcode, config);
                return newObject;
            }
        }
        return newObject;
    },

    arduinoCompatibleOpcode: function (oldObject, configList) {
        const newObject = {...oldObject};
        const opcode = oldObject.opcode;
        if (fieldsMapping.hasOwnProperty(opcode)) {
            if (typeof fieldsMapping[opcode] === 'function') {
                return fieldsMapping[opcode](newObject);
            }
            newObject.opcode = fieldsMapping[opcode].opcode;
            return newObject;
        }
        for (let i = 0; i < configList.length; i++) {
            const config = configList[i];
            const extensionId = opcode.substring(0, opcode.indexOf('.'));
            if (mainProgramHeader.includes(opcode)) {
                const prefix = config.isDevice ? 'dev-' : 'ext-';
                const author = config.author;
                const version = config.version;
                newObject.opcode = `${prefix}${author}-${config.id}@${version}_start`;
                return newObject;
            }
            // 内置扩展的兼容处理
            if (config.oldId === extensionId) {
                newObject.opcode = transformCoreExtensionId(opcode, config.newId);
                return newObject;
            }
            if (config.compatiblityId === extensionId) {
                newObject.opcode = transFromOpcode(opcode, config);
                return newObject;
            }
        }
        return newObject;
    }

};

// 导出模块
module.exports = CompatibilityModule;
