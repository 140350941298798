import {DeviceTarget} from './deviceTarget';
import VM, { vm } from "../../lib/scratch-vm";
import { ExtensionDataType } from 'service/ext-asset-manager/extension/type';
import { message } from 'antd';
import { checkFuzzyVersion } from 'util/compareVersion';
import { IS_ARDUINO_MODE, IS_SCRATCH_MODE, MINDPLUS_MODE } from 'config/config';
import Serialport, { CustomErrorCallback } from 'service/link-adapter/serialport';

// 设备相关接口
export class DeviceManager {
    vm: VM
    ONLINE_MODE = 'online'
    UPLOAD_MODE = 'upload'
    _currentDevice?: DeviceTarget
    _devicesInfo: { [deviceId: string]: { config: any, deviceObject: any } }
    _modules = {} // 小模块列表(主板小模块, 舞台小模块)
    _port: string = ""
    _serial: Serialport|null = null
    _serialMessage: string = ""
    _onSerialMessagesCallback: Function | null = null;
    _outputMessage: string = ""
    _onOutputMessagesCallback: Function | null = null;
    _baudRate: number = 9600
    constructor(vm) {
        this.vm = vm;
        this._devicesInfo = {} // {[deviceId: string]: object}设备的功能接口
    }

    // 创建设备
    async createDevice(config): Promise<void> {
        if (this._currentDevice) {
            // todo: 清除上个设备的资源
            await this._currentDevice.dispose();
        }
        this._currentDevice = new DeviceTarget(this, this.vm, config)
        // todo: 刷新新设备支持的小模块

    }

    // 删除设备
    async deleteDevice() {
        if (this._currentDevice) {
            // todo: 清除上个设备的资源
            await this._currentDevice.dispose();
        }
        this._currentDevice = undefined;
    }
  
    get currentDevice() {
      return this._currentDevice;
    }
  
    // 获取设备扩展对象(getInfo)
    getDeviceExtensionObj() {
        return this._currentDevice && this._currentDevice.getDeviceExtensionObj();
    }

    // 添加小模块
    addModule(extensionId, config) {
        this._modules[extensionId] = config;
    }

    // 移除小模块
    removeModule(extensionId) {
        vm.extensionManager.deleteExtension(extensionId)
        delete this._modules[extensionId];
    }
    
    // 移除所有的硬件小模块(舞台扩展不移除)
    removeAllDeviceModules() {
        for (let id in this._modules) {
            let config = this._modules[id];
            // 是否是设备的小模块
            if (!config.isSupportStage) {
                this.removeModule(id);
            }
        }
    }

    // 切换新主板之后, 刷新当前的硬件小模块, 不支持新主板的移除(舞台扩展不刷新)
    refreshAllDeviceModules(newDeviceIdWithVersion) {
        let [newDeviceId, version] = newDeviceIdWithVersion.split('@');
        for (let id in this._modules) {
            let config = this._modules[id];
            // 是否是设备的小模块
            if (!config.isSupportStage) {
                // 判断是否支持新主板
                if (config.supportDevices&&config.supportDevices[newDeviceId]) {
                    // 判断是否支持新主板的版本
                    if (checkFuzzyVersion(config.supportDevices[newDeviceId], version)) continue;
                }
                this.removeModule(id);
            }
        }
        return this.vm.extensionManager.refreshBlocks();
    }

    getCurrentDeviceId() {
        return this._currentDevice&&this._currentDevice.config.deviceId||'';
    }

    getCurrentDeviceVersion() {
        return this._currentDevice&&this._currentDevice.config.version||'';
    }

    // 当前target是不是上传模式
    isArduinoMode() {
        return IS_ARDUINO_MODE;
    }

    // 当前target是不是实时模式
    isScratchMode(targetId?) {
        return IS_SCRATCH_MODE;
    }

    // 获取当前target的当前模式
    getCodeMode(targetId?) {
        return MINDPLUS_MODE;
    }

    /****************** scratch模式功能 **************/
    // 是否连接
    isConnected(): boolean {
        if (!this._currentDevice) return false
        return this._currentDevice.isScratchConnected();
    }

    // 连接
    connect() {
        if (!this._currentDevice) {
            return Promise.reject('未加载设备')
        }
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        // 先断开上一个连接
        return this.disconnect().then(() => this._currentDevice!.connectScratch(port));
    }

    // 断开连接
    disconnect() {
        if (!this._currentDevice) {
            return Promise.reject("未加载设备")
        }
        return this._currentDevice.disconnectScratch();
    }

    // 监听scratch连接状态
    listenScratchConnectStatus() {
        
    }

    /*****************串口 ************* */
    // 切换当前设备的串口
    changePort(port) {
        this._port = port;
        let promise: any = () => Promise.resolve()
        // 先断开连接
        if (IS_SCRATCH_MODE) {
            promise = () => this.disconnect();
        }
        if (IS_ARDUINO_MODE) {
            promise = () => this.closeSerial();
        }
        // 切换串口为空
        if (!port) return promise();
        let promise2: any = () => Promise.resolve()
        if (IS_SCRATCH_MODE) {
            promise2 = () => this.connect();
        }
        return promise().then(() => promise2());
    }

    // 获取当前串口信息
    getCurrentPort() {
        return this._port;
    }

    // 打开串口
    openSerial() {
        // 没有选择串口
        if (!this._port) return Promise.reject("没有选择串口")
        // 已经打开
        if (this._serial) return Promise.reject("串口已经打开")
        return new Promise((resolve, reject) => {
            this._serial = new Serialport(this._port, {
                baudRate: this._baudRate,
            })
            this._serial.on('data', (msg: string) => {
                // 添加到串口消息
                this._pushSerialMessage(msg.toString());
            })
            this._serial.on('error', (err: string) => {
                // 输出打印
                this._pushOutputMessage(err.toString());
                reject();
            })
            this._serial.on('open', ()=>{
                resolve(0);
            })
        })
    }

    // 关闭串口
    closeSerial() {
        return new Promise((resolve, reject) => {
            if (!this._serial) return resolve(0)
            this._serial.close((err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(0);
                }
            })
        })
    }

    // 监听串口状态
    listenSerialStatus() {

    }
    
    getSerialStatus() {
        return this._serial?.isOpen
    }

    serialWrite(msg: string, callback?: CustomErrorCallback) {
        if (!this._serial) return callback && callback(new Error("serial is not open."));
        this._serial.write(msg, callback)
    }

    updateSerialBaudRate(baudRate: number, callback?: CustomErrorCallback) {
        this._baudRate = baudRate;
        if (!this.getSerialStatus()) return callback && callback(null);
        this._serial!.update({baudRate}, callback)
    }

    getSerialBaudRate() {
        return this._baudRate;
    }

    /******************* 消息处理 **********/
    // 添加串口消息
    _pushSerialMessage(message) {
        this._serialMessage += message;
        if (this._onSerialMessagesCallback) this._onSerialMessagesCallback(this._serialMessage);
    }

    // 清除串口消息
    clearSerialMessage() {
        this._serialMessage = "";
        if (this._onSerialMessagesCallback) this._onSerialMessagesCallback(this._serialMessage);
    }

    // 监听串口消息
    listenSerialMessage(callback: Function|null) {
        this._onSerialMessagesCallback = callback
        if (callback) callback(this._serialMessage)
    }

    // 添加输出信息
    _pushOutputMessage(message) {
        this._outputMessage += message;
        if (this._onOutputMessagesCallback) this._onOutputMessagesCallback(this._outputMessage);
    }

    // 清除输出信息
    clearOutputMessage() {
        this._outputMessage = "";
        if (this._onOutputMessagesCallback) this._onOutputMessagesCallback(this._outputMessage);
    }

    // 监听输出信息
    listenOutputMessage(callback: Function|null) {
        this._onOutputMessagesCallback = callback
        if (callback) callback(this._outputMessage)
    }
    

    /********************** arduino模式功能 *******************/
    // 烧录
    upload() {
        if (!this._currentDevice) {
            return Promise.reject('未加载设备')
        }
        let promise: any = Promise.resolve()
        // 上传之前, 断开连接
        if (IS_ARDUINO_MODE) {
            promise = this.closeSerial()
        }
        if (IS_SCRATCH_MODE) {
            promise = this.disconnect()
        }
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        return this._currentDevice.upload(port);
    }
    
    // 编译
    compile(code) {
        if (!this._currentDevice) return Promise.reject("cannot find device");
        return this._currentDevice.compile(code);
    }

    // 编译后上传
    compileAndUpload(code) {
        if (!this._currentDevice) return Promise.reject("cannot find device");
        return this.compile(code).then(() => this.upload())
    }
}
