import React from 'react';
import Stage from "./stage/Stage";
import styles from './StageWrapper.module.scss'
// import {TargetPane} from "./target-pane/TargetPane";
import {TargetPane} from "component/target-pane";
import {ControlBar} from "./control-bar/ControlBar";
import {useSelector} from "react-redux";
import {selectStageSize} from "redux/stage-size/stageSizeSlice";
import {selectMode} from "redux/mode/modeSlice";
const StageWrapper = () => {
    const {stageSize} = useSelector(selectStageSize)
    const {isFullScreen} = useSelector(selectMode)

    return (
        <div dir={'ltr'} className={stageSize === 'large'?styles.containerLarge:styles.containerSmall}>
            {isFullScreen?(
                <div className={styles.fullscreenStageWrapper}>
                    <div className={styles.controlBar}><ControlBar /></div>
                    <div className={styles.stage}><Stage /></div>
                </div>
            ):(
                <>
                    <div className={styles.controlBar}><ControlBar /></div>
                    <div className={styles.stage}><Stage /></div>
                </>
            )}
            <div className={styles.sprite}><TargetPane/></div>
        </div>
    );
};

export default StageWrapper;
