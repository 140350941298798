// @flow
import message from "component/df-message/Message";
import { IS_SCRATCH_MODE, IS_WEB_PLATFORM } from "config/config";
import * as React from "react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { openLinkConnect } from "redux/link-modal/linkModalSlice";
import {
  loadDevice,
  unloadDevice,
} from "service/ext-asset-manager/device/loadDevice";
import { loadExtension } from "service/ext-asset-manager/extension/loadExtension";
import { commandWs } from "service/link-adapter/websocket/commandWs";
import { vm } from "../../../lib/scratch-vm";
import { selectIntl } from "../../../redux/intl/intlSlice";
import { DeviceDataType } from "../../../service/ext-asset-manager/device/type";
import { compareVersions } from "../../../service/ext-asset-manager/device/util";
import { ExtensionDataType } from "../../../service/ext-asset-manager/extension/type";
import { WaveLoader } from "../../wave-loader/WaveLoader";
import { LibDeviceData } from "../util/useDeviceData";
import { LibExtensionData } from "../util/useExtensionData";
import styles from "./ExtensionLibrariesItem.module.scss";
import { useShowingImage } from "./util/useShowingImage";
import { ContextMenuTrigger } from "lib/react-contextmenu/modules";
import { MenuItem, ContextMenu } from 'component/context-menu/context-menu'

export type LibrariesItemProps = {
  libType: "extension" | "device" | "module";
  items: LibDeviceData[] | LibExtensionData[]; // 传递的是该扩展的不同版本的config.json
};

let contextMenuId = 0;

//
export const ExtensionLibrariesItem = (props: LibrariesItemProps) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const { locale } = useSelector(selectIntl);
  const descriptionRef = React.useRef<HTMLDivElement>(null);
  // 显示版本的config
  const [activeItem, setActiveItem] = useState<
    LibDeviceData | LibExtensionData
  >(props.items[props.items.length - 1]);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  // 显示的图片
  const { imageSrc } = useShowingImage(activeItem);

  const isDevice = props.libType === "device";
  //是否是scratch内置扩展
  const isScratchBuiltInExtension =
    !isDevice && (activeItem as any)?.isBuiltinScratch;

  let isSupportCurrentDevice = true;
  if (!activeItem.isDevice) {
    isSupportCurrentDevice = activeItem.isSupportCurrentDevice;
  }

  // 是否加载了主板
  const currentDeviceId = vm.runtime.deviceManager.getCurrentDeviceId();

  //是否禁用右键菜单
  const isDisabledContextMenu = useMemo(() => {
    if (activeItem.isDevice) {
      return !activeItem.isDownloaded || activeItem.isBuiltin
    }
    if (!activeItem.isDevice) {
      if (activeItem.isBuiltinScratch) {
        return true
      }
      return !activeItem.isDownloaded || activeItem.isBuiltin
    }
  }, [activeItem])
  // 当扩展被刷新
  useEffect(() => {
    // 优先显示被加载的版本
    let loadedItem;
    props.items.forEach((item) => {
      if (item.isLoaded) {
        loadedItem = item;
      }
    });
    // 如果没有加载, 显示最新版本
    if (loadedItem) {
      setActiveItem(loadedItem);
    } else {
      setActiveItem(props.items[props.items.length - 1]);
    }
  }, [props.items]);

  const handleDownloadResource = (e) => {
    e.stopPropagation();
    //TODO: 在线版兼容 没有运行link的情况给用户提示
    setDownloading(true);
    let promise: Promise<any> = Promise.resolve();
    if (isDevice) {
      promise = vm.runtime.extAssetManager.downloadDevice(
        activeItem as DeviceDataType
      );
    } else {
      promise = vm.runtime.extAssetManager.downloadExtension(
        activeItem as ExtensionDataType
      );
    }
    promise
      .then(() => {
        // 刷新扩展库列表
        vm.emit("refreshExtensionLib");
      })
      .catch(() => {
        message.error("下载失败");
      })
      .finally(() => {
        // 结束下载状态
        setDownloading(false);
      });
  };

  const handleUpdateResource = async (e) => {
    e.stopPropagation();
    if (!activeItem) return;
    let updateItem: any = null;
    // 找到未下载的 版本号大于当前选中的版本号 且支持当前设备版本的的
    props.items.some((item) => {
      if (
        !item.isDownloaded &&
        compareVersions(item.version, activeItem.version) === 1
      ) {
        // 如果是设备
        if (item.isDevice) {
          updateItem = item;
          return true;
          // 如果是小模块
        } else if (item.isSupportCurrentDevice) {
          updateItem = item;
          return true;
        }
      }
      return false;
    });
    if (!updateItem) return;
    setDownloading(true);
    try {
      if (isDevice) {
        await vm.runtime.extAssetManager.downloadDevice(
          updateItem as DeviceDataType
        );
      } else {
        await vm.runtime.extAssetManager.downloadExtension(
          updateItem as ExtensionDataType
        );
      }
    } catch (e) {
      console.log("eeee======", e);
    } finally {
      setDownloading(false);
    }
  };

  // 点击加载
  const onClickItem = () => {
    if (!activeItem) return;
    if (activeItem.isLoaded) return;
    // 离线版, 未下载的不能加载
    if (!IS_WEB_PLATFORM && !activeItem?.isDownloaded) {
      message.info("未下载的扩展, 无法加载");
      return;
    }
    // 在线版, 设备未下载 不能加载
    if (IS_WEB_PLATFORM && activeItem.isDevice && !activeItem?.isDownloaded) {
      message.info("未下载的扩展, 无法加载");
      return;
    }
    // 不支持的不能加载
    if (
      props.libType === "module" &&
      !(activeItem as LibExtensionData).isSupportCurrentDevice
    ) {
      if (!currentDeviceId) {
        message.info("请先加载设备!");
      } else {
        message.info(
          `当前模块支持的设备: ${JSON.stringify(
            (activeItem as any).supportDevices
          )}`
        );
      }
      return;
    }
    // 判断link是否启动, 设备加载必须启动link
    if (activeItem.isDevice && !commandWs.isOpen()) {
      // 触发link启动弹窗
      dispatch(openLinkConnect());
      return;
    }

    let promise = new Promise((resolve, reject) => {
      if (activeItem.isDevice) {
        if (vm.runtime.deviceManager.currentDevice) {
          vm.runtime.dfrobotAlert(
            intl.formatMessage(messages.prompt),
            intl.formatMessage(messages.changeBoard),
            {
              cover: true,
              btns: [
                { text: intl.formatMessage(messages.yes), callBack: () => resolve(loadDevice(activeItem)) },
                { text: intl.formatMessage(messages.no), callBack: () => reject("cancel") }
              ]
            });
        } else {
          resolve(loadDevice(activeItem));
        }
      } else {
        resolve(loadExtension(activeItem));
      }
    })
    promise
      .then(() => {
        console.log("加载成功");
        // dispatch(closeExtensionLib())
        vm.emit("refreshExtensionLib");
      })
      .catch((e) => {
        if (e === 'cancel') return
        message.error("加载扩展失败!");
      });
  };

  // 卸载
  const removeExtension = (e) => {
    e.stopPropagation()
    let id;
    if (activeItem.isDevice) {
      id = activeItem.deviceIdWithVersion;
    } else {
      id = activeItem.extensionIdWithVersion || activeItem.extensionId;
    }

    // 方案1: 不提示, 直接移除, 可能会删除该扩展以外的block
    // vm.extensionManager.deleteExtension(id)

    // 方案2
    // 1.检测当前workspace有没有该扩展的block
    let ret = vm.runtime.workspaceHasExtensionBlock(id);

    // 2.没有就直接移除
    if (!ret) {
      if (activeItem.isDevice) {
        unloadDevice().then(() => {
          // 刷新扩展库页面
          vm.emit("refreshExtensionLib")
        })
      } else {
        vm.extensionManager.deleteExtension(id);
        // 刷新扩展库页面
        vm.emit("refreshExtensionLib")
      }
    } else {
      // 3.有的话, 提示 扩展正在被使用, 无法移除
      message.warning("扩展正在使用, 无法移除", 2000);
      return
    }
  }

  // 切换版本
  const onChangeVersion = (val: string) => {
    // 1.等于当前版本
    if (val === activeItem.version) return;
    let id;
    if (activeItem.isDevice) {
      id = activeItem.deviceIdWithVersion;
    } else {
      id = activeItem.extensionIdWithVersion || activeItem.extensionId;
    }
    const newItem = (props.items as any).find((item) => item.version === val);
    // 2.当前扩展已加载
    if (activeItem.isLoaded) {
      // 1.检测当前workspace有没有该扩展的block
      let ret = vm.runtime.workspaceHasExtensionBlock(id);
      if (ret) {
        // 有的话, 提示 扩展正在被使用, 无法切换版本
        message.warning("扩展正在使用, 无法切换版本", 4);
        return;
      } else {
        // 移除旧的扩展
        vm.extensionManager.deleteExtension(id);
        // 加载新的扩展
        let promise = Promise.resolve();
        if (newItem.isDevice) {
          promise = loadDevice(newItem);
        } else {
          promise = loadExtension(newItem);
        }

        promise
          .then(() => {
            message.success("切换版本成功");
            vm.emit("refreshExtensionLib");
          })
          .catch((e) => {
            message.error("切换版本失败");
          });
      }
    }

    setActiveItem(newItem);
  };

  const deleteResource = async (e) => {
    e.stopPropagation();
    try {
      if (activeItem.isDevice) {
        if (activeItem.isLoaded) {
          await unloadDevice()
        }
        await vm.runtime.extAssetManager.removeDeviceFromLocal(activeItem)
        message.success("删除设备成功");
      } else {
        if (activeItem.isLoaded) {
          vm.extensionManager.deleteExtension(activeItem.extensionIdWithVersion);
        }
        await vm.runtime.extAssetManager.removeExtensionFromLocal(activeItem)
        message.success("删除扩展成功");
      }
      vm.emit("refreshExtensionLib",true);
    } catch (error) {
      console.log("error===", error)
      message.error("删除扩展失败");
    }
  }
  // 1.已加载的(ok)

  // 2.不支持的(ok)

  // 3.有更新的

  // 4.下载

  // 5.更新

  let showStatus = "";
  // 1.离线版有更新/下载, 已加载的扩展不显示更新/下载按钮
  // 2.在线版的设备有更新/下载
  if ((!IS_WEB_PLATFORM || activeItem.isDevice || !IS_SCRATCH_MODE) && !activeItem.isLoaded) {
    // link未连接
    if (!commandWs.isOpen()) {
      showStatus = "needLink";
    } else if (!activeItem.isDownloaded) {
      showStatus = "download";
    } else if (activeItem.hasUpdate) {
      showStatus = "update";
    }
  }
  // 设置描述信息样式
  useEffect(() => {
    const applyEllipsis = (element, lines) => {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * lines;
      element.style.maxHeight = `${maxHeight}px`;

      let text = element.innerText;
      while (element.scrollHeight > element.clientHeight) {
        text = text.substring(0, text.length - 1);
        element.innerText = text + "...";
      }
    };

    if (descriptionRef.current) {
      applyEllipsis(descriptionRef.current, 2);
    }
  }, []);

  return (
    <ContextMenuTrigger
      attributes={{
        onClick: onClickItem,
      }}
      disable={isDisabledContextMenu}
      id={`${activeItem.id}-${contextMenuId}`}
    >
      <div
        className={`${styles.spriteCard} ${activeItem.isLoaded ? styles.selected : ""
          }`}
      >
        {isDownloading && (
          <div className={styles.downloading}>
            <WaveLoader itemCount={3} loadingText={"下载中"} />
          </div>
        )}
        {showStatus === "needLink" && (
          <div className={styles.needLink}>需要Mind-Link</div>
        )}
        {!isSupportCurrentDevice && (
          <div className={styles.disabled}>
            <p style={{ color: "#fff" }}>{"不支持当前设备"}</p>
          </div>
        )}

        <div className={styles.image}>
          {activeItem.isLoaded && (
            <div className={styles.remove} onClick={removeExtension}>
              移除
            </div>
          )}
          {showStatus === "download" && (
            <div className={styles.download} onClick={handleDownloadResource}>
              下载
            </div>
          )}
          {/* {showStatus==="update"&&<div className={styles.update} onClick={handleUpdateResource}>更新</div>} */}
          <img src={imageSrc} />
          <div className={styles.versions} onClick={(e) => e.stopPropagation()}>
            {/* {
                    !isScratchBuiltInExtension && 
                    <Select size={"small"} value={activeItem.version} options={props.items.map((item) => ({label: item.version, value: item.version}))}
                        onChange={(val) => onChangeVersion(val)}
                        variant="borderless"
                    />
                } */}
          </div>
        </div>
        <div className={styles.sku}>{activeItem?.sku}</div>
        <div className={styles.featuredText}>
          {isScratchBuiltInExtension ? (
            <>
              <div className={styles.name}>{activeItem?.name as ReactNode}</div>
              <div className={styles.description} ref={descriptionRef}>
                {activeItem?.description as any}
              </div>
            </>
          ) : (
            <>
              <div className={styles.name}>
                {activeItem && activeItem.name && activeItem.name[locale]}
              </div>
              <div className={styles.description}>
                {activeItem &&
                  activeItem.description &&
                  activeItem.description[locale]}
              </div>
            </>
          )}
        </div>
      </div>
      {
        !isDisabledContextMenu && <ContextMenu id={`${activeItem.id}-${contextMenuId}`} >
          <MenuItem onClick={deleteResource}>删除</MenuItem>
        </ContextMenu>
      }
    </ContextMenuTrigger>
  );
};


const messages = defineMessages({
  prompt: {
    id: "gui.dialog.prompt",
    default: "Note"
  },
  changeBoard: {
    id: 'gui.dialog.changeBoard',
    default: 'Switching board will clear the current program and continue'
  },
  yes: {
    id: 'gui.dialog.yes',
    default: 'Yes'
  },
  no: {
    id: 'gui.dialog.no',
    default: 'No'
  },
})
