import { EventEmitter } from "events";
import JSONRPC from "./jsonrpc";
import LinkWebSocket from "./link-websocket";

class Stdout extends EventEmitter {
    constructor() {
        super();
    }
    // ...
}

class ChildProcessWs extends JSONRPC {
    ws: LinkWebSocket
    stderr: Stdout = new Stdout()
    stdout: Stdout = new Stdout()
    isExit: boolean = false
    constructor() {
        super();
        this.ws = new LinkWebSocket("child_process");
        this.ws.setOnOpen(this._onOpen.bind(this));
        this.ws.setOnClose(this._onClose.bind(this));
        this.ws.setOnError(this._onError.bind(this));
        this.ws.setHandleMessage(this._handleMessage.bind(this));
        // 重写jsonrpc的方法
        this._sendMessage = this.ws.sendMessage.bind(this.ws);
        // 连接
        this.ws.open();
        this.on("error", console.log)
    }
    
    sendRemoteRequest (method: string, params?: any) {
        if (!this.isOpen()) return Promise.reject("websocket not connected");
        return super.sendRemoteRequest(method, params)
    }

    sendRemoteNotification(method: string, params?: any) {
        if (!this.isOpen()) return
        super.sendRemoteNotification(method, params)
    }

    close() {
        this.ws.close();
        this.emit("exit")
    }

    isOpen() {
        return this.ws.isOpen();
    }

    _onOpen() {
        this.emit("open")
    }

    _onClose() {
        this.emitExitEvent(0);
    }

    _onError(err) {
        this.emit("error", err)
    }

    // 触发退出事件
    emitExitEvent(code: number|null) {
        // 避免触发多次exit事件
        if (this.isExit) return;
        this.isExit = true;
        this.emit('exit', code);
    }

    // 重写jsonrpc的方法
    // 这里是处理 下位机-->上位机 的request, 不是response
    didReceiveCall (method, params) {
        // 子进程的消息上报
        switch(method) {
            case "stderrData": 
                this.stderr.emit('data', params);
                break;
            case "stdoutData": 
                this.stdout.emit('data', params);
                break;  
            case "error": 
                this.emit('error', params);
                break;  
            case "exit": 
                this.emitExitEvent(params)
                break;  
        }
    }
}

export default ChildProcessWs;
