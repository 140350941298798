// @flow
import DownArrowIcon from "asset/image/down_arrow_icon.svg";
import DFDropdown from "component/df-dropdown/DFDropdown";
import downloadBlob from "lib/download-blob";
import { vm } from "lib/scratch-vm";
import { useDispatch, useSelector } from "react-redux";
import { selectProject, setProjectError } from 'redux/project/projectSlice';
import { useCreateProject, useLoadLocalProject } from 'service/project/useProject';
import styles from "../MenuBar.module.scss";
import dfrobotAlert from 'component/df-alert/DFAlert'
import { defineMessages, useIntl } from "react-intl";
import ScratchBlocks from "scratch-blocks";
import { useEffect } from "react";

export const Project = () => {
  const { projectTitle, projectChanged, projectError } = useSelector(selectProject)
  const loadLocalProject = useLoadLocalProject()
  const createProject = useCreateProject()
  const intl = useIntl()
  const dispatch = useDispatch()

  const onLoadLocaleProject = () => {
    loadLocalProject()
  }
  const onSaveProjectToLocal = (callback?: Function) => {
    vm.saveProjectSb3().then(content => {
      downloadBlob(`${projectTitle}.sb3`, content);
    }).then(() => {
      if (callback) callback()
    })
  }

  const onLoadNewProject = () => {
    //TODO: 考虑云项目逻辑
    if (!projectChanged) {
      createProject()
      return
    }
    dfrobotAlert(intl.formatMessage(messagesAlert.prompt), intl.formatMessage(messagesAlert.isSaveProject),
      {
        timeout: 0,
        details: '',
        cover: true,
        btns: [
          {
            text: intl.formatMessage(messagesAlert.save), callBack: () => onSaveProjectToLocal(createProject)
          },
          {
            text: intl.formatMessage(messagesAlert.notSave), callBack: createProject
          }
        ]
      });
  }
  const onDropdownClick = ({ key }) => {
    switch (key) {
      case '1':
        //关闭flyout
        ScratchBlocks.hideChaff()
        onLoadNewProject()
        break
      case '2':
        //关闭flyout
        ScratchBlocks.hideChaff()
        onLoadLocaleProject()
        break
      case '3':
        onSaveProjectToLocal()
        break
      default:
        break
    }

  }

  const items = [
    {
      key: '1',
      label: '新建项目',
    },
    {
      key: '2',
      label: '打开项目',
    },
    {
      key: '3',
      label: '保存到电脑',
    },

  ];

  useEffect(() => {
    if (projectError) {
      dfrobotAlert(intl.formatMessage(messagesAlert.error), intl.formatMessage(messagesAlert.unsupportProject), { timeout: 8000, zIndex: 1000 })
      dispatch(setProjectError(null))
      createProject()
    }
  }, [createProject, dispatch, intl, projectError])
  return (
    <DFDropdown trigger={'click'} items={items} onItemClick={onDropdownClick} width={'100'}>
      <div className={styles.dropdownContent}>
        <span >项目</span>
        <img src={DownArrowIcon} />
      </div>
    </DFDropdown>
  );
};


const messagesAlert = defineMessages({
  prompt: {
    id: "gui.dialog.prompt",
    default: "Note"
  },
  isSaveProject: {
    id: 'gui.dialog.isSaveProject',
    default: 'Whether to save the current project'
  },
  save: {
    id: 'gui.dialog.save',
    default: 'Save'
  },
  notSave: {
    id: 'gui.dialog.notSave',
    default: 'Not Save'
  },
  error: {
    id: "gui.dialog.error",
    default: "Error"
  },
  unsupportProject: {
    id: 'gui.dialog.unsupportProject',
    default: 'Unsupported item</br> is reloading a blank item'
  }
})
