// @flow
import * as React from 'react';
import {useSelector} from "react-redux";
import {selectVmStatus} from "redux/vm-status/vmStatusSlice";
import styles from './ControlBar.module.scss'
import {FullScreenControl} from "./full-screen-control/FullScreenControl";
import {StageControl} from "./stage-control/StageControl";
import {VmControl} from "./vm-control/VmControl";
import {selectMode} from "redux/mode/modeSlice";
import {selectStageSize} from "redux/stage-size/stageSizeSlice";
import {getStageDimensions} from "lib/screen-utils";
import {TurboMode} from "./turbo-mode/TurboMode";

type Props = {

};
export const ControlBar = (props: Props) => {
    const {started} = useSelector(selectVmStatus)
    const {isFullScreen} = useSelector(selectMode)
    const {stageSize} = useSelector(selectStageSize)
    if (!started) return null;
    // 1.全屏 (退出全屏  加速模式/stop/run)

    // 2.playerOnly (全屏   加速模式/stop/run)

    // 3.正常(全屏  large/small   加速模式/stop/run)

    const stagesize = getStageDimensions(stageSize, isFullScreen)
    return (
        <div className={styles.containerWrapper}>
            <div className={styles.container} style={{width: `${stagesize.width}px`}}>
                <div className={styles.runWrapper}>
                    <VmControl />
                    <TurboMode/>
                </div>
                <div className={styles.stageControl}>
                    {!isFullScreen&&<StageControl />}
                    <FullScreenControl />
                </div>
            </div>
        </div>
    )
};
