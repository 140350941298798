import {spawn, exec} from "./child_process"
import { getArduinoCliPath, getArduinoCliConfigPath, getArduinoPath } from "service/path/assetPath";
import { vm } from "lib/scratch-vm";
import { commandWs } from "./websocket/commandWs";
import { EXTENSION_ASSET_SERVER } from "config/config";
import { LibraryConfigItem } from "service/ext-asset-manager/device/type";

// 添加package url
export function addPackageIndex(packageIndex: string) {
    const child = spawn(getArduinoCliPath(), [
        "--config-file",
        getArduinoCliConfigPath(),
        "config",
        "add",
        "board_manager.additional_urls",
        packageIndex,
    ])
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // todo: 输出arduino-cli信息
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            console.log("code", code)
            if (code) return reject(code)
            resolve();
        })
    })
}

// 安装主板
export function installBoard(packageName: string, version?: string) {
    const child = spawn(getArduinoCliPath(), [
        "--config-file",
        getArduinoCliConfigPath(),
        "core",
        "install",
        version?`${packageName}@${version}`:packageName,
    ])
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            if (code) return reject(code)
            resolve();
        })
    })
}

export function updatePackageIndex() {
    const child = spawn(getArduinoCliPath(), [
        "--config-file",
        getArduinoCliConfigPath(),
        "core",
        "update-index",
    ])
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            if (code) return reject(code)
            resolve();
        })
    })
}

export function compile(params: {
    boardName: string
    buildPath: string //
    buildCachePath?: string
    libraries?: string[]
    inoFile: string
    fqbn?: string
}) {
    const args = [
        "--config-file",
        getArduinoCliConfigPath(),
        "-v",
        "compile",
        "-b",
        params.boardName,
        "--build-path",
        params.buildPath,
        "--build-cache-path",
        params.buildCachePath?params.buildCachePath:params.buildPath,
    ]
    if (params.libraries && params.libraries.length) {
        args.push("--libraries");
        args.push(params.libraries.join(","));
    }
    
    if (params.fqbn) {
        args.push("--fqbn");
        args.push(params.fqbn);
    }
    
    args.push(params.inoFile);
    const child = spawn(getArduinoCliPath(), args)
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            if (code) return reject(code)
            resolve();
        })
    })
}

export function upload(params:{
    boardName: string
    inputDir?: string //
    inputFile?: string
    port: string
    protocol?: string
}) {
    const args = [
        "--config-file",
        getArduinoCliConfigPath(),
        "-v",
        "upload",
        "-b",
        params.boardName,
        "-p",
        params.port,
        "--protocol",
        params.protocol||'serial'
    ]
    if (params.inputFile) {
        args.push("--input-file");
        args.push(params.inputFile);
    } else {
        args.push("--input-dir");
        args.push(params.inputDir||'');
    }

    const child = spawn(getArduinoCliPath(), args)
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            if (code) return reject(code)
            resolve();
        })
    })
}

// 更新libraries_index.json
// arduino-cli中不可指定library_index.json的下载路径, 所以从我们服务器去手动更新
export function updateLibraryIndex() {
    return commandWs.sendRemoteRequest("downloadFile", {
        url: `${EXTENSION_ASSET_SERVER}/arduino-libraries/library_index.json`,
        targetPath: `${getArduinoPath()}/library_index.json`,
    })
}

// 安装库, first: 是否是第一次install
export function installLibrary(libItem: LibraryConfigItem, first: boolean = true) {

    const child = spawn(getArduinoCliPath(), [
        "--config-file",
        getArduinoCliConfigPath(),
        "lib",
        "install",
        libItem
    ])
    return new Promise<void>((resolve, reject) => {
        child.stdout.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.stderr.on('data', (data) => {
            // console.log(data.toString());
            vm.runtime.deviceManager._pushOutputMessage(data.toString())
        })
        child.on('exit', (code) => {
            if (code) return reject(code)
            resolve();
        })
    })
    .catch(e => {
        // 如果安装失败, 更新library_index.json之后, 再次安装
        if (first) {
            // 更新json
            return updateLibraryIndex().then(() => installLibrary(libItem, false))
        }
        return Promise.reject(e)
    })
}